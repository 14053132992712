<template>
  <div class="home-title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
}
</script>
<style lang="scss" scoped>
.home-title {
  
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 232px;
  height: 30px;
  background-image: url('../assets/img/redesign/biaoti.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 18px;
}
</style>
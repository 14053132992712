var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part3 part"},[_vm._m(0),_c('TitleMain',[_vm._v(" "+_vm._s(_vm.$t('guanwang1.tzfp'))+" ")]),_c('div',{staticClass:"tzfp"},[_c('div',{staticClass:"tzfp-top"},[_c('div',{staticClass:"zgyl-key"},[_vm._v(_vm._s(_vm.$t('guanwang1.zgyl'))+"： ")]),_c('div',{staticClass:"zgyl-value"},[_vm._v("1000000000")])]),_c('div',{staticClass:"tzfp-bottom"},_vm._l(([
                '#55baf0',
                '#60bef1',
                '#6ac0f1',
                '#77c5f1',
                '#81c8f1',
                '#8dccf1',
                '#94cef2',
                '#a1d3f2',
                '#abd7f5',
                '#2bccd2',
                '#2bd1d7',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
                '#2bccd2',
            ]),function(item){return _c('div',{staticClass:"tzfp-bg",style:({ 'background-color': item })})}),0)]),_c('div',{staticClass:"tzfp-content"},[_c('img',{staticClass:"tzfp-yinying",attrs:{"src":require("../../../assets/img/r2/yinying2.png"),"alt":"","srcset":""}}),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"tz-border"},[_vm._v(" "+_vm._s(_vm.$t('guanwang1.us20'))+" ")]),_c('div',{staticClass:"tz-border bz-bnt__wj"},[_vm._v(" "+_vm._s(_vm.$t('guanwang1.us19'))+" ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wangge-img"},[_c('img',{attrs:{"src":require("../../../assets/img/r2/tzlast.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tzfo-logo"},[_c('img',{attrs:{"src":require("../../../assets/img/r2/3.png"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tz-btn"},[_c('div',{staticClass:"tzbtn-text"},[_vm._v(" 99% ")]),_c('img',{attrs:{"src":require("../../../assets/img/r2/tzbtn.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tz-btn tz-bnt--right"},[_c('div',{staticClass:"tzbtn-text"},[_vm._v(" 1% ")]),_c('img',{attrs:{"src":require("../../../assets/img/r2/tzbtn.png"),"alt":""}})])
}]

export { render, staticRenderFns }
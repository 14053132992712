<template>
    <div class="part part5">
        <div class="wangge-img">
            <img src="../../../assets/img/r2/tzlast.png" alt="">
        </div>
        <TitleMain>
            {{ $t('guanwang1.us30') }}
        </TitleMain>
        <!-- <HomeTile class="margin-bottom__20px">
            {{$t('guanwang1.us30') }}
        </HomeTile> -->

        <div class="lxwm">
            <div class="lxwm-inner">
                <div class="i-item">
                    <div class="i-left">Email</div>
                    <div class="i-right">etf.digital.token@gmail.com</div>
                </div>
                <div class="i-item">
                    <div class="i-left">Tg</div>
                    <div class="i-right">https://t.me/MFTDigital</div>
                </div>
                <div class="i-item">
                    <div class="i-left">Tw</div>
                    <div class="i-right">https://twitter.com/MFTDigital</div>
                </div>
                <div class="i-item">
                    <div class="i-left  bnone">Os</div>
                    <div class="i-right  bnone">www.digital-mft.com</div>
                </div>
                <img class="yinying5" src="../../../assets/img/r2/yinying5.png" alt="">
                <img class="yinying6" src="../../../assets/img/r2/yinying6.png" alt="">
            </div>
        </div>

        <template v-if="false">
            <div class="concat-item">
                <div class="c-name">Email</div>
                <div class="c-text">etf.digital.token@gmail.com</div>
            </div>
            <div class="concat-item">
                <div class="c-name">TG</div>
                <div class="c-text">https://t.me/MFTDigital</div>
            </div>
            <div class="concat-item">
                <div class="c-name">TW</div>
                <div class="c-text">
                    https://twitter.com/MFTDigital</div>
            </div>
            <div class="concat-item">
                <div class="c-name">OS</div>
                <div class="c-text">
                    www.digital-mft.com</div>
            </div>
            <div class="part5-content" v-if="false">
                <div v-for="(item, index) of list" :key="index" class="cell">
                    <div class="question">
                        {{ $t(item.qKey) }}
                    </div>
                    <div class="answer">
                        {{ $t(item.aKey) }}
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<style scoped lang="scss">
.part5 {
    position: relative;
    .wangge-img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 179px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .lxwm {
        position: relative;
        margin: 0 auto;
        overflow: hidden;
        margin-top: 40px;
        width: 344px;
        height: 222px;
        border: 1px solid #4a697e;
        padding: 15px;
        box-sizing: border-box;
        .lxwm-inner {
            border: 1px solid #357495;
            .i-item {
                display: flex;
                width: 310px;

                .i-left {
                    text-align: center;
                    line-height: 46px;
                    width: 68px;
                    height: 46px;
                    border-right: 1px solid #305d78;
                    border-bottom: 1px solid #305d78;
                    &.bnone {
                        border-bottom: 0px solid #305d78;
                    }
                }

                .i-right {
                    line-height: 46px;
                    text-align: center;
                    flex-grow: 1;
                    font-size: 11px;
                    border-bottom: 1px solid #305d78;
                    &.bnone {
                        border-bottom: 0px solid #305d78;
                    }
                }
            }
        }
        .yinying5 {
            position: absolute;
            width: 85px;
            right: -20px;
            top: 30px;
        }
        .yinying6 {
            position: absolute;
            left: 0;
            right: 0px;
            bottom: 0;
        }
    }

    .c477 {
        width: 185px;
        height: 185px;
        margin: 0 auto;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .concat-item {
        display: flex;
        margin-bottom: 26px;
        margin-left: 40px;

        .c-name {
            font-weight: bold;
            font-size: 11px;
            color: #00F1FB;
            margin-right: 16px;
        }

        .c-text {
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF;
        }
    }

    .part5-content {
        padding: 20px;

        .cell {
            width: 100%;
            min-height: 200px;
            margin-bottom: 20px;
            padding: 35px 28px;
            background-image: url('~@/assets/img/Home/Group 1321316810@2x.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            text-align: left;

            .question {
                font-weight: 500;
                font-size: 20px;
                color: #4986FF;
                line-height: 30px;
                margin-bottom: 20px;
                font-weight: 700;
            }

            .answer {
                font-weight: 500;
                font-size: 20px;
                color: #FFFFFF;
                line-height: 30px;
            }
        }
    }
}
</style>

<script>
import HomeTile from '@/components/HomeTitile.vue'
import TitleMain from '@/components/TitleMain.vue'

export default {
    components: {
        HomeTile,
        TitleMain
    },
    data () {
        return {
            list: [{
                q: '为什么要开发ETF公链',
                a: '为了让ETF在链上执行，这样会更公开和公平',
                qKey: 'guanwang1.us22',
                aKey: 'guanwang1.us23'
            }, {
                q: 'ETF可以在其他公链执行吗',
                a: '可以实现的，不过由于公链的机制特性，会被节点套利',
                qKey: 'guanwang1.us24',
                aKey: 'guanwang1.us25'
            }, {
                q: 'ETF公链和EVM公链的区别',
                a: '修改了GAS机制，保证节点无法提前排队，让金融更安全',
                qKey: 'guanwang1.us26',
                aKey: 'guanwang1.us27'
            }, {
                q: 'ETF一直没在公链执行的原因',
                a: '目前EVM的排队机制，可以让节点在内网进行排队，这样就可以100%套利',
                qKey: 'guanwang1.us28',
                aKey: 'guanwang1.us29'
            }]
        }
    }
}
</script>
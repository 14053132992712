var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part part5"},[_vm._m(0),_c('TitleMain',[_vm._v(" "+_vm._s(_vm.$t('guanwang1.us30'))+" ")]),_vm._m(1),(false)?[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),(false)?_c('div',{staticClass:"part5-content"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"cell"},[_c('div',{staticClass:"question"},[_vm._v(" "+_vm._s(_vm.$t(item.qKey))+" ")]),_c('div',{staticClass:"answer"},[_vm._v(" "+_vm._s(_vm.$t(item.aKey))+" ")])])}),0):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wangge-img"},[_c('img',{attrs:{"src":require("../../../assets/img/r2/tzlast.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lxwm"},[_c('div',{staticClass:"lxwm-inner"},[_c('div',{staticClass:"i-item"},[_c('div',{staticClass:"i-left"},[_vm._v("Email")]),_c('div',{staticClass:"i-right"},[_vm._v("etf.digital.token@gmail.com")])]),_c('div',{staticClass:"i-item"},[_c('div',{staticClass:"i-left"},[_vm._v("Tg")]),_c('div',{staticClass:"i-right"},[_vm._v("https://t.me/MFTDigital")])]),_c('div',{staticClass:"i-item"},[_c('div',{staticClass:"i-left"},[_vm._v("Tw")]),_c('div',{staticClass:"i-right"},[_vm._v("https://twitter.com/MFTDigital")])]),_c('div',{staticClass:"i-item"},[_c('div',{staticClass:"i-left bnone"},[_vm._v("Os")]),_c('div',{staticClass:"i-right bnone"},[_vm._v("www.digital-mft.com")])]),_c('img',{staticClass:"yinying5",attrs:{"src":require("../../../assets/img/r2/yinying5.png"),"alt":""}}),_c('img',{staticClass:"yinying6",attrs:{"src":require("../../../assets/img/r2/yinying6.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"concat-item"},[_c('div',{staticClass:"c-name"},[_vm._v("Email")]),_c('div',{staticClass:"c-text"},[_vm._v("etf.digital.token@gmail.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"concat-item"},[_c('div',{staticClass:"c-name"},[_vm._v("TG")]),_c('div',{staticClass:"c-text"},[_vm._v("https://t.me/MFTDigital")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"concat-item"},[_c('div',{staticClass:"c-name"},[_vm._v("TW")]),_c('div',{staticClass:"c-text"},[_vm._v(" https://twitter.com/MFTDigital")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"concat-item"},[_c('div',{staticClass:"c-name"},[_vm._v("OS")]),_c('div',{staticClass:"c-text"},[_vm._v(" www.digital-mft.com")])])
}]

export { render, staticRenderFns }
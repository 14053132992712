<template>
    <div class="part4 part">
        <!-- <HomeTile class="margin-bottom__20px">
            {{ 'FAQs' }}
        </HomeTile> -->
        <!-- <div class="font-size__36px margin-bottom__10px">
            {{ $t(`guanwang1.us17`) }}
        </div> -->
        <div class="wangge-img">
            <img src="../../../assets/img/r2/tzlast.png" alt="">
        </div>
        <TitleMain>
            FAQs
        </TitleMain>

        <div class="p4-wrapper">
            <div class="o-0 o-1">
                <img class="yinying1" src="../../../assets/img/r2/yinying1.png" alt="">
                <div class="neirong1" :class="$store.state.language == '英语' ? 'yingyu' : ''">{{ $t(`guanwang1.q1`) }}</div>
                <div class="neirong2" :class="$store.state.language == '英语' ? 'yingyu' : ''">{{ $t(`guanwang1.a1`) }}</div>
            </div>
            <div class="o-0 o-2">
                <div class="neirong1 nei-right" :class="$store.state.language == '英语' ? 'yingyu' : ''">{{ $t(`guanwang1.q2`) }}</div>
                <div class="neirong2 nei-right" :class="$store.state.language == '英语' ? 'yingyu' : ''">{{ $t(`guanwang1.a2`) }}</div>
            </div>
            <div class="o-0 o-3">
                <img class="yinying2" src="../../../assets/img/r2/yinying4.png" alt="">
                <div class="neirong1" :class="$store.state.language == '英语' ? 'yingyu' : ''">{{ $t(`guanwang1.q3`) }}</div>
                <div class="neirong2" :class="$store.state.language == '英语' ? 'yingyu' : ''">{{ $t(`guanwang1.a3`) }}</div>
            </div>
            <div class="o-0 o-4">
                <div class="neirong1 nei-right" :class="$store.state.language == '英语' ? 'yingyu' : ''">{{ $t(`guanwang1.q4`) }}</div>
                <div class="neirong2 nei-right" :class="$store.state.language == '英语' ? 'yingyu' : ''">{{ $t(`guanwang1.a4`) }}</div>
            </div>
        </div>

        <div class="p4-content" v-if="false">
            <div class="li">
                <div class="c-item--title">
                    <img src="../../../assets/img/redesign/tuoyuan1.png" alt="">
                    {{ $t(`guanwang1.part4q1`) }}
                </div>
                <div class="c-item--text">{{ $t(`guanwang1.part4a1`) }}</div>
            </div>

            <div class="li">
                <div class="c-item--title">
                    <img src="../../../assets/img/redesign/tuoyuan1.png" alt="">
                    {{ $t(`guanwang1.part4q2`) }}
                </div>
                <div class="c-item--text">{{ $t(`guanwang1.part4a2`) }}</div>
            </div>

            <div class="li">
                <div class="c-item--title">
                    <img src="../../../assets/img/redesign/tuoyuan1.png" alt="">
                    {{ $t(`guanwang1.part4q3`) }}
                </div>
                <div class="c-item--text">{{ $t(`guanwang1.part4a3`) }}</div>
            </div>

            <div class="li">
                <div class="c-item--title">
                    <img src="../../../assets/img/redesign/tuoyuan1.png" alt="">
                    {{ $t(`guanwang1.part4q4`) }}
                </div>
                <div class="c-item--text">{{ $t(`guanwang1.part4a4`) }}
                </div>
            </div>
        </div>

        <template v-if="false">
            <div class="part4-text margin-bottom__20px">
                {{ $t(`guanwang1.us18`) }} <span class="font-family-Number font-size__20px">1000000000</span>
            </div>

            <div class="part4-content font-family-Number">
                <div class="part4-content-number">
                    1%
                </div>
                <div class="part4-content-inset">
                    99%
                </div>
            </div>

            <div class="part4-data">
                <div class="part4-data-left">
                    <img :src="ShowImage" alt="">
                </div>
                <div class="part4-data-right font-family-Number">
                    <div class="part4-data-right__top display-flex__align-center__justify-between">
                        <div class="title">
                            {{ $t(`guanwang1.us19`) }}
                        </div>
                        <div class="data">
                            1%
                        </div>
                    </div>
                    <div class="part4-data-right__bottom display-flex__align-center__justify-between">
                        <div class="title">
                            {{ $t(`guanwang1.us20`) }}
                        </div>
                        <div class="data">
                            99%
                        </div>
                    </div>
                </div>
            </div>

            <div class="part4-image">
                <img :src="ShowImage2" alt="">
            </div>
        </template>
    </div>
</template>

<style scoped lang="scss">
.part4 {
    margin: 0 21px;
    position: relative;
    margin-bottom: 0 !important;

    .yinying1 {
        position: absolute;
        width: 120px;
        right: -20px;
    }
    .yinying2 {
        position: absolute;
        width: 120px;
        left: -20px;
    }

    .wangge-img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 179px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .p4-wrapper {
        margin-top: 28px;

        .o-0 {
            position: relative;
            width: 100%;
            height: 110px;
            background-size: 100% 100%;
            background-repeat: no-repeat;

            .neirong1 {
                width: 280px;
                text-align: right;
                position: absolute;
                left: 30px;
                top: 52px;
                font-size: 16px;

                &.nei-right {
                    right: -100px;
                }
                &.yingyu {
                    font-size: 10px;
                }
            }

            .neirong2 {
                position: absolute;
                left: 30px;
                top: 80px;
                font-size: 10px;
                color: #7e8b9c;
                width: 290px;
                text-align: right;

                &.nei-right {
                    right: -0px;
                }

                &.yingyu {
                    font-size: 8px;
                }
            }
        }

        .o-1 {
            background-image: url('../../../assets/img/r2/o1.png');
        }

        .o-2 {
            background-image: url('../../../assets/img/r2/o2.png');
        }

        .o-3 {
            background-image: url('../../../assets/img/r2/o3.png');
        }

        .o-4 {
            background-image: url('../../../assets/img/r2/o4.png');
        }
    }

  

    .p4-content {
        padding-top: 33px;
        padding-left: 24px;
        width: 333px;
        height: 409px;
        background-image: url('../../../assets/img/redesign/bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        box-sizing: border-box;

        .li {
            text-align: left;
            margin-bottom: 45px;

            .c-item--title {
                min-height: 12px;
                font-weight: 400;
                font-size: 13px;
                color: #00F1FB;
                margin-bottom: 15px;

                img {
                    width: 13px;
                    height: 13px;
                }
            }

            .c-item--text {
                height: 10px;
                font-size: 11px;
                color: #F5F5F5;
            }
        }
    }

    .part4-image {
        width: 100%;

        img {
            width: 90%;
        }
    }

    .part4-data {
        padding: 10px;
        width: 90%;
        height: 100px;
        margin: 10px auto;

        background: linear-gradient(180deg, rgba(61, 115, 255, 0) 0%, rgba(61, 127, 255, 0.3) 100%);
        border: 1px solid;
        border-image: linear-gradient(360deg, rgba(61, 139, 255, 1), rgba(61, 162, 255, 0)) 1 1;

        position: relative;
        top: 20px;

        .part4-data-left {
            float: left;
            width: 100px;

            img {
                width: 100px;
                height: 80px;
                object-fit: contain;
            }
        }

        .part4-data-right {
            width: calc(100% - 100px);
            box-sizing: border-box;
            float: right;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            height: 80px;
            padding: 10px;

            .part4-data-right__top {
                width: 100%;
                border-bottom: 1px dashed #7789CD;
                padding: 5px 0;
            }

            .part4-data-right__bottom {
                width: 100%;
                padding: 5px 0;
            }

            .title {
                font-size: 16px;
                color: #FFFFFF;
            }

            .data {
                font-size: 24px;
                color: #839EFF;
                line-height: 24px;
            }
        }
    }

    .part4-text {
        font-size: 18px;
        line-height: 20px;
        background: linear-gradient(0deg, #3877F2 0%, #0029FF 100%);
        -webkit-background-clip: text;
        color: transparent;
    }

    .part4-content {
        width: 90%;
        height: 20px;
        margin: 0 auto;
        border-radius: 10px;
        background: linear-gradient(90deg, #2D5CBD 0%, #40A0FA 100%);
        position: relative;

        .part4-content-number {
            position: absolute;
            height: 20px;
            color: #fff;
            line-height: 20px;
            right: 10px;
        }

        .part4-content-inset {
            width: 92%;
            height: 20px;
            border-radius: 10px;
            background: linear-gradient(90deg, rgba(8, 255, 255, 0.1) 0%, #08FFFF 100%);

            color: #fff;
            line-height: 20px;
            padding-left: 10px;

            position: relative;
            z-index: 1;
        }
    }
}</style>

<script>
import HomeTile from '@/components/HomeTitile.vue'

import ShowImage from "@/assets/img/Home/Group 1321316819@2x.png"
import ShowImage2 from "@/assets/img/Home/组合 85@2x.png"
import TitleMain from '@/components/TitleMain.vue'

export default {
    components: {
        HomeTile,
        TitleMain
    },
    data () {
        return {
            ShowImage,
            ShowImage2
        }
    }
}
</script>
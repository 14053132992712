<template>
    <div class="part3 part">
        <!-- <HomeTile class="margin-bottom__20px">
            {{ $t('guanwang1.tzfp') }}
        </HomeTile> -->
        <div class="wangge-img">
            <img src="../../../assets/img/r2/tzlast.png" alt="">
        </div>
        <TitleMain>
            {{ $t('guanwang1.tzfp') }}
        </TitleMain>

        <!-- <div class="part3-title font-size__36px margin-bottom__20px  padding-left__5px padding-right__5px">
            {{ $t(`guanwang1.us16`) }}
        </div> -->
        <!-- <div class="gongying">
            {{ $t('guanwang1.zgyl') }}&nbsp;1000000000
        </div> -->

        <!-- <div class="bai99">
            <img src="../../../assets/img/redesign/99.png" alt="">
        </div> -->

        <div class="tzfp">
            <div class="tzfp-top">
                <div class="zgyl-key">{{ $t('guanwang1.zgyl') }}： </div>
                <div class="zgyl-value">1000000000</div>
            </div>
            <div class="tzfp-bottom">
                <div class="tzfp-bg" :style="{ 'background-color': item }" v-for="item in [
                    '#55baf0',
                    '#60bef1',
                    '#6ac0f1',
                    '#77c5f1',
                    '#81c8f1',
                    '#8dccf1',
                    '#94cef2',
                    '#a1d3f2',
                    '#abd7f5',
                    '#2bccd2',
                    '#2bd1d7',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                    '#2bccd2',
                ]">
                </div>
            </div>
        </div>
        <div class="tzfp-content">
            <img class="tzfp-yinying" src="../../../assets/img/r2/yinying2.png" alt="" srcset="">
            <div class="tzfo-logo">
                <img src="../../../assets/img/r2/3.png" alt="" srcset="">
            </div>

            <div class="tz-btn">
                <div class="tzbtn-text">
                    99%
                </div>
                <img src="../../../assets/img/r2/tzbtn.png" alt="">
            </div>
            <div class="tz-btn tz-bnt--right">
                <div class="tzbtn-text">
                    1%
                </div>
                <img src="../../../assets/img/r2/tzbtn.png" alt="">
            </div>

            <div class="tz-border">
                {{ $t('guanwang1.us20') }}
            </div>
            <div class="tz-border bz-bnt__wj">
                {{ $t('guanwang1.us19') }}
            </div>
        </div>

        <!-- <img class="tz-last" src="../../../assets/img/r2/tzlast.png" alt=""> -->

        <!-- <div class="part3-c" v-if="false">
            <div class="p2-c-box box-1">
                <div class="box-inner--top">{{ $t('guanwang1.zgyl') }}</div>
                <div class="box-inner--bottom">1%</div>
            </div>
            <div class="p3-logo">
                <img src="../../../assets/img/redesign/logo.png" alt="">
            </div>
            <div class="p2-c-box box-1">
                <div class="box-inner--top">流动性</div>
                <div class="box-inner--bottom">99%</div>
            </div>
        </div> -->
        <!-- <div class="part3-content">
            <img :src="RoundImage" alt="">
        </div> -->
    </div>
</template>


<style scoped lang="scss">
.part3 {
    position: relative;
    height: 476px;
    margin-bottom: 0px !important;
    .wangge-img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 179px;

        img {
            width: 100%;
            height: 100%;
        }
    }
    .gongying {
        margin: 0 auto;
        font-family: Source Han Sans CN;
        font-size: 12px;
        color: #00F6FF;
    }

    .bai99 {
        margin: 0 36px;
        height: 14px;
        margin-top: 15px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .tzfp {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 120px;
        width: 242px;
        height: 70px;
        background-image: url('../../../assets/img/r2/tzfpbg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        z-index: 11;

        
        .tzfp-top {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 38px;
        }

        .tzfp-bottom {
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;

            .tzfp-bg {
                width: 7px;
                height: 14px;
                margin-right: 1px;
            }

        }


    }

    .tzfp-content {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 155px;
        width: 345px;
        height: 320px;
        background-image: url('../../../assets/img/r2/tzbg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        font-size: 16px;
        .tzfp-yinying {
            width: 120px;
            position: absolute;
            left: 0;
            top: -20px;
            z-index: 12;
        }
        .tzfo-logo {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 90px;
            width: 80px;
            height: 90px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .tz-btn {
        position: absolute;
        left: 27px;
        top: 90px;
        height: 26px;
        width: 96px;
        height: 106px;
        img {
            width: 100%;
            height: 100%;
        }

        &.tz-bnt--right {
            left: 220px;
        }

        .tzbtn-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .tz-border {
        font-size: 11px;
        position: absolute;
        left: 27px;
        bottom: 54px;
        display: flex;
        justify-content: center;
        line-height: 36px;
        width: 96px;
        height: 40px;
        background-image: url('../../../assets/img/r2/tbborder.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        &.bz-bnt__wj {
            left: 220px;
        }
    }

    .part3-c {
        margin: 0 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        .p2-c-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 96px;
            height: 47px;
            padding: 0 9px;
            background-image: url('../../../assets/img/redesign/bgkaobei.png');
            background-repeat: no-repeat;
            background-size: cover;
            box-sizing: border-box;
            text-align: left;

            .box-inner--top {
                font-weight: 400;
                font-size: 10px;
                color: #FFFFFF;
            }

            .box-inner--bottom {
                font-size: 10px;
                color: #02FAFC;
            }
        }

        .p3-logo {
            width: 130px;
            height: 99px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .part3-content {
        padding: 20px;
        overflow: hidden;

        img {
            width: 100%;
            height: 45vh;
            object-fit: contain;
            display: block;
            transform: scale(1.5);
        }
    }
}
</style>

<script>
import HomeTile from '@/components/HomeTitile.vue'
import RoundImage from "@/assets/img/Home/gif/2_100.gif"
import TitleMain from '@/components/TitleMain.vue'

export default {
    components: {
        HomeTile,
        TitleMain
    },
    data () {
        return {
            RoundImage
        }
    }
}
</script>
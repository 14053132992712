<template>
    <div class="part1 part">
        <!-- <div class="earth">
            <img :src="EarthImage" alt="">
        </div> -->
        <!-- <div class="part1-title font-size__24px margin-bottom__20px padding-left__5px padding-right__5px">
            {{ $t(`guanwang1.us1`) }}
        </div> -->
        <div class="part1-top">
            <div class="top-logo">
                <img src="../../../assets/img/r2/3.png" alt="" srcset="">
            </div>
            <div class="wh">
                {{ $t('guanwang1.whjyjj') }}
            </div>
            <div class="platform">
                {{ $t('guanwang1.qqlx') }}
                <br />
                {{ $t('guanwang1.qzxh') }}
            </div>
            <div class="anniu" :class="$store.state.language == '英语' ? 'yingyu' : ''" @click="Jump">
                {{ $t(`guanwang1.us3`) }}
            </div>
        </div>
        <img class="yinying1" src="../../../assets/img/r2/yinying1.png" alt="">
        <div class="ht">
            <div class="ht-left flex">{{ $t(`guanwang1.hetong`) }}</div>
            <div class="ht-center">
                {{address}}
            </div>
            <div class="ht-right flex" @click="Copy">
                <img src="../../../assets/img/r2/copy.png" alt="">
            </div>
        </div>

       
        
        <!-- <div class="part1-text-4">
            <div class="display-flex__align-center__justify-center" @click="Copy">
                <div class="address">
                   <span class="hetong">{{ $t(`guanwang1.hetong`) }} &nbsp;</span> 
                   <div class="address-flex">
                    {{address}}
                    <img class="button-icon" :src="copyIcon">
                   </div>
                </div>
            </div> 
        </div> -->
        <!-- <div class="part1-button">
            <div @click="Jump" class="button-jump">
                {{ $t(`guanwang1.us3`) }}
            </div>
        </div> -->
        <div class="part1-icons" v-if="false">
            <div v-for="item of 3" :key="item" class="part1-icons__cell">
                <img class="iconGif" :src="IconGif" alt="">
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.part1 {
    margin-bottom: 0 !important;
    .part1-top {
        padding-left: 12px;
        height: 262px;
        background-image: url('../../../assets/img/r2/banner.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        .top-logo {
            width: 58px;
            height: 58px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .wh {
            text-align: left;
            font-size: 26px;
            color:  #1fffff;
            line-height: 30px;
        }
        .platform {
            text-align: left;
            font-size: 28px;
            line-height: 36px;
            color: #53c1f3;
        }
        .anniu {
            width: 128px;
            height: 50px;
            background-image: url('../../../assets/img/r2/anniu.png');
            background-size: 100% 100%;
            line-height: 46px;
            text-align: center;
            background-repeat: no-repeat;
            margin-top: 20px;
            font-size: 12px;
            margin-left: -6px;
            color: #4aabd8;

            &.yingyu {
                font-size: 7px;
            }
        }
    }

    .yinying1 {
            position: absolute;
            top: 220px;
            width: 100px;
            right: -0px;
        }
    .ht {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 400;
        
        .yinying2 {
            position: absolute;
            width: 120px;
            left: 0px;
        }
        .flex {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        margin: 0 auto;
        width: 346px;
        height: 22px;
        background-image: url('../../../assets/img/r2/ht.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        .ht-left {
            width: 46px;
            height: 12px;
            border-right: 1px solid #36577a;
        }
        .ht-center {
            flex-grow: 1;
        }
        .ht-right {
            width: 32px;
            height: 12px;
            border-left: 1px solid #36577a;
            img {
                width: 18px;
                height: 18px;
            }
        }
    }
    .zhanwei-img {
        width: 100%;
        height: 45vh;
        -o-object-fit: contain;
        object-fit: contain;
        display: block;
        transform: scale(1.5);
        // background-image: url('../../../assets/img/redesign/bg8.png');
        // background-size: contain;
        // background-repeat: no-repeat;
    }
    // > div {
    //     margin: 0px auto 10px;
    //     text-align: center;
    //     font-style: normal;
    //     text-transform: none;
    //     font-family: DIN, DIN;
    // }
    .part1-icons {
        display: flex;
        width: 95%;

        .part1-icons__cell {
            flex: 1;
            position: relative;
            height: 150px;
            display: flex;
            justify-content: center;
            width: 100px;
            transform: scale(.8);
            overflow: hidden;


            img {
                display: block;
                position: absolute;
                // left: 50%;
                // transform: translateX(-50%);
            }
            .iconGif {
                width: 100%;
                transform: scale(1.5);
            }
            .top {
                width: 100px;
                height: 100px;
                object-fit: contain;
                z-index: 2;
                opacity: .8;
                animation: opacity108 3s infinite;
            }
            .light {
                top: 40px;
                z-index: 3;
                width: 120px;
                animation: opacity01 3s infinite;
                opacity: 0;
            }
            .bottom {
                width: 100px;
                height: 60px;
                top: 70px;
            }
        }
    }
    .part1-button {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            background: linear-gradient( 90deg, #3877F2 0%, #0029FF 100%);
            border-radius: 8px 8px 8px 8px;
            padding: 8px 18px;
            display: inline-block;
        }
        .button-jump {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 146px;
            height: 31px;
            background-image: url('../../../assets/img/redesign/anniuxuanzhong.png');
            background-size: cover;
            font-weight: bold;
            font-size: 11px;
            color: #00F6FF;
        }
    }
    .part1-text-4 {
        font-size: 12px;
        color: #0285FF;
        margin-bottom: 20px;
        padding: 10px;
        padding-top: 5px;
        

        .address {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            height: 18px;

            // transform: scale(0);
            .hetong {
                    font-weight: bold;
                    font-size: 14px;
                    color: #fff;
                }
            .address-flex {
                font-size: 11px;
                color: #00F1FB;
                display: flex;
            }
            &:active {
                color: rgba(2,133,255,.3);
                border-bottom: 1px solid rgba(2,133,255,.3);
            }
        }
    }

    .part1-title {
        margin-bottom: 30px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 22px;
        color: #FFFFFF;
        text-shadow: 0 0 0px ,0 0 1px #00F1FB,0 0 1px #00F1FB,0 0 1px #00F1FB;	//设置发光效果
    }
    .part1-text-3 {
        font-size: 20px;
        color: #277CF7;
        line-height: 30px;
        margin-bottom: 5px;

        img {
            height: 24px;
            width: 24px;
        }
    }
    .part1-text-2 {
        font-size: 18px;
        color: #FFFFFF;
        line-height: 20px;
        margin-bottom: 20px;
    }
    .part1-text-1 {
        font-size: 24px;
        line-height: 20px;
        background: linear-gradient(0deg, #3877F2 0%, #0029FF 100%);
        -webkit-background-clip: text;
        color: transparent;
        margin-bottom: 50px;
    }
    .earth {
        overflow: hidden;
        img {
            width: 100%;
            height: 45vh;
            object-fit: contain;
            display: block;
            transform: scale(1.5);
        }
    }
}


</style>

<script>
// import EarthImage from "@/assets/img/Home/gif/3_100.gif"
import EarthImage from "@/assets/img/r2/banner.png"
import Icon1 from "@/assets/img/Home/Group 1321316817@2x.png"
import copyIcon from "@/assets/img/redesign/fuzhi.png"


import IconGif from "@/assets/img/Home/gif/1_100.gif"
// import TopModuleImage from "@/assets/img/Home/Group 1321316813@2x.png"
// import LightModuleImage from "@/assets/img/Home/Group 1321316814@2x.png"
// import BottomModuleImage from "@/assets/img/Home/Group 1321316767@2x.png"

export default {
    data() {
        return {
            EarthImage,
            Icon1,
            copyIcon,
            IconGif,
            // TopModuleImage,
            // LightModuleImage,
            // BottomModuleImage

            address: '0xc7eECaaA37E4F5D0d11344ea6f3C9E77580A9f53'
        }
    },
    methods: {
        Copy() {
            this.$global.CopyContent(this.address, () => {
                this.showSuccessMessage(this.$i18n.t('tishi.us1111'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'))
            })
        },
        Jump() {
            this.$router.push('/Mint')
        }
    },
    watch: {
        '$store.state.lang': function (val) {
            console.log(val,'111');
        }
    }
}
</script>
<template>
    <div class="part2 part">
        <!-- <div class="part2-title margin-bottom__20px">
            {{ $t(`guanwang1.us4`) }}
        </div> -->
        <div class="wangge-img">
            <img src="../../../assets/img/r2/tzlast.png" alt="">
        </div>
        <!-- <HomeTile class="margin-bottom__20px p0">
            {{ $t(`guanwang1.us4`) }}
        </HomeTile> -->
        <TitleMain> 
            {{ $t(`guanwang1.us4`) }}
        </TitleMain>
        <!-- <div class="part2-text-1 font-size__14px margin-bottom__20px padding-left__5px padding-right__5px">
            {{ $t(`guanwang1.us5`) }}
        </div> -->

        <div class="mubiao" :class="$store.state.language == '英语' ? 'yingyu' : ''">
            <div class="mubiao-top">{{ $t(`guanwang1.us501`) }}</div>
            <div class="mubiao-top">{{ $t(`guanwang1.us502`) }}</div>
        </div>


        <div class="ul-kuang">
            <div class="li-item" v-for="(item, index) in lineData" :key="item.tKey">
                <div class="kuang1 flex">{{'0' + (index + 1)}}</div>
                <div class="kuang2 flex">{{$t(item.dateKey)}}</div>
                <div class="kuang3 flex">{{$t(item.contentKey)}}</div>
            </div>

            <div class="other">
                <div class="o-item" v-for="item in 4" :key="item">
                </div>
            </div>
        </div>

       


        <div class="part2-line padding-top__20px" v-if="false">
            
            <div v-for="(item, index) of lineData" :key="index" class="part2-line-cell">
                <div class="part2-line-cell__left">
                    <div v-if="index % 2 == 0" class="part2-line-cell__content float-right">
                        {{ $t(item.contentKey) }}
                    </div>
                    <div v-else class="part2-line-cell__date float-right text-align__right">
                        {{ $t(item.dateKey) }}
                    </div>
                </div>
                <div class="part2-line-cell__right">   
                    <div v-if="index % 2 != 0" class="part2-line-cell__content float-left">
                        {{ $t(item.contentKey) }}
                    </div>
                    <div v-else class="part2-line-cell__date float-left text-align__left">
                        {{ $t(item.dateKey) }}
                    </div>
                    <div class="sphere"></div>
                </div>
            </div>

            <div class="line"></div>
        </div>

        <div class="part2-content" v-if="false">
            <img class="c-2-img" src="../../../assets/img/redesign/bg7.png" alt="">
            <div class="p2-c-box box-1">
                <div class="box-inner--top">{{ $t(`guanwang1.partr1name`) }}</div>
                <div class="box-inner--bottom">{{ $t(`guanwang1.partr1text`) }}</div>
            </div>

            <div class="p2-c-box box-2">
                <div class="box-inner--top">{{ $t(`guanwang1.partr2name`) }}</div>
                <div class="box-inner--bottom">{{ $t(`guanwang1.partr2text`) }}</div>
            </div>

            <div class="p2-c-box  box-3">
                <div class="box-inner--top">{{ $t(`guanwang1.partr3name`) }}</div>
                <div class="box-inner--bottom">{{ $t(`guanwang1.partr3text`) }}</div>
            </div>

            <div class="p2-c-box  box-4">
                <div class="box-inner--top">{{ $t(`guanwang1.partr4name`) }}</div>
                <div class="box-inner--bottom">{{ $t(`guanwang1.partr4text`) }}</div>
            </div>

            <div class="p2-c-box box-5">
                <div class="box-inner--top">{{ $t(`guanwang1.partr5name`) }}</div>
                <div class="box-inner--bottom">{{ $t(`guanwang1.partr5text`) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import HomeTile from '@/components/HomeTitile.vue'
import TitleMain from '@/components/TitleMain.vue'

import BorderContent from "@/assets/img/Home/Group 17@2x(1).png"
export default {
    components: {
        HomeTile,
        TitleMain
    },
    data() {
        return {
            BorderContent,
            lineData: [{
                tKey: 'line1',
                content: 'ETF 挖掘',
                date: '2024 年 8 月',

                contentKey: 'guanwang1.us6',
                dateKey: 'guanwang1.us7'
            },{
                tKey: 'line2',
                content: 'IM 通讯',
                date: '2024 年 11 月',

                contentKey: 'guanwang1.us8',
                dateKey: 'guanwang1.us9'
            },{
                tKey: 'line3',
                content: 'EETF公链测试网',
                date: '2025 年 5 月',

                contentKey: 'guanwang1.us10',
                dateKey: 'guanwang1.us11'
            },{
                tKey: 'line4',
                content: 'ETF 主网',
                date: '2025 年 8 月',

                contentKey: 'guanwang1.us12',
                dateKey: 'guanwang1.us13'
            },{
                tKey: 'line5',
                content: 'ETF 生态',
                date: '2025 年 12 月',

                contentKey: 'guanwang1.us14',
                dateKey: 'guanwang1.us15'
            }]
        }
    }
}
</script>

<style scoped lang="scss">
.part2 {
    margin-bottom: 0 !important;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .wangge-img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 179px;

        img {
            width: 100%;
            height: 100%;
        }
    }
    .p0 {
        position: absolute;
        top: 0px;
    }
    .mubiao {
        margin: 0 auto;
        margin-top: 40px;
        width: 240px;
        height: 64px;
        background-image: url('../../../assets/img/r2/mubiao.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.yingyu {
            font-size: 11px;
        }
    }

    .ul-kuang {
        padding: 12px;
        padding-top: 33px;
        width: 345px;
        height: 362px;
        background-image: url('../../../assets/img/r2/mubiaobg.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        .li-item {  
            display: flex;
            color: #6bbdf5;
            margin-bottom: 6px;
            
            .flex {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .kuang1 {
                background-image: url('../../../assets/img/r2/kuang1.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                width: 75px;
                height: 50px;
            }
            .kuang2 {
                background-image: url('../../../assets/img/r2/kuang2.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                width: 125px;
                height: 50px;
            }
            .kuang3 {
                background-image: url('../../../assets/img/r2/kuang3.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                width: 150px;
                height: 50px;
            } 
        }
    }

    .other {
        display: flex;
        margin-top: 24px;
        .o-item {
            width: 6px;
            height: 6px;
            background-color: #6ebff1;
            margin: 0 4px;
        }
    }

    .part2-title {
        position: absolute;
        top: 0px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 232px;
        height: 30px;
        background-image: url('../../../assets/img/redesign/biaoti.png');
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 16px;
    }
    .part2-text-1 {
        position: absolute;
        top: 40px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 10px;
        color: #FFFFFF;
        line-height: 12px;
        opacity: 0.6;
    }
    .part2-content {
        position: relative;
        width: 100%;
        height: 350px;
        // background-image: url('../../../assets/img/redesign/bg7.png');
        // background-repeat: no-repeat;
        // background-size: cover;
        .c-2-img {
            width: 100%;
            height: 100%;
        }
        .p2-c-box {
            position: absolute;
            width: 96px;
            height: 47px;
            padding-top: 8px;
            padding-left: 12px;
            background-image: url('../../../assets/img/redesign/bgkaobei.png');
            background-repeat: no-repeat;
            background-size: cover;
            box-sizing: border-box;
            text-align: left;
            .box-inner--top {
                font-weight: 400;
                font-size: 10px;
                color: #02FAFC;
                margin-bottom: 5px;
            }
            .box-inner--bottom {
                font-size: 10px;
                color: #FFFFFF;
            }
        }

        .box-1 {
            top: 75px;
            left: 52px;
        }
        .box-2 {
            top: 75px;
            right: 48px;
        }
        .box-3 {
            top: 200px;
            left: 27px;
        }
        .box-4 {
            top: 175px;
            right: 17px;
        }
        .box-5 {
            left: 50%;
            transform: translateX(-50%);
            bottom: 20px;
        }
    }

    .part2-line {
        padding: 20px 0 0;
        position: relative;
        .line {
            position: absolute;
            width: 4px;
            height: 78%;
            top: 50px;
            left: 50%;
            transform: translateX(-50%);
            background: #0066FE;
            border-radius: 0px 0px 0px 0px;
        }
        .part2-line-cell {
            width: 100%;
            min-height: 160px;

            .part2-line-cell__left {
                width: 50%;
                float: left;
                padding-right: 20px;
            }
            .part2-line-cell__right {
                width: 50%;
                float: right;
                padding-left: 20px;
                position: relative;
                .sphere {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -8px;
                    width: 16px;
                    height: 16px;
                    background: #1D77FF;
                    border-radius: 50%;
                    z-index: 1;
                }
            }

            .part2-line-cell__content {
                width: 150px;
                min-height: 70px;
                background-image: url('~@/assets/img/Home/Group 17@2x(1).png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 16px;
                text-align: left;
                padding: 20px;
            }
            .part2-line-cell__date {
                // width: 140px;
                min-height: 70px;
                font-size: 20px;
                color: #0075FE;
                line-height: 23px;
                display: flex;
                align-items: center;
            }
        }
    }
}
</style>
<template>
  <div class="tti-wrapper">
    <div class="title-content">
    <img class="tc-1" src="../assets/img/r2/titlemain.png" alt="">
    <div class="title-m">
        <img class="tc-2" src="../assets/img/r2/titleleft.png" alt="">
        <div class="title-text">
          <slot />
        </div>
        <img class="tc-3" src="../assets/img/r2/titleright.png" alt="">
    </div>
  </div>
  </div>
  
</template>

<script>
  export default {
      data () {
        return {
          
        }
      },
  }
</script>
<style lang="scss" scoped>
.tti-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
  .title-content {
    position: relative;
    width: 228px;
    .tc-1 {
      display: block;
      width: 100%;
      height: 80px;
    }
    .title-m {
      position: absolute;
      top: 70px;
      left: 50%;
      transform: translateX(-50%);
      width: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 30px;
      }
      .title-text {
        font-size: 18px;
        line-height: 26px;
        color: #6dbef0;
        margin-left: 2px;
        margin-right: 2px;
      }
    }
  }
</style>